<template>
  <div>
    <my-nav-bar
      title="业务提成"
      left-text="返回"
      left-arrow
    />
    <van-sticky offset-top="1.22667rem">
      <van-cell title="日期" :value="dateString" is-link center @click="showPopup = true" />
      <van-cell title="类型" :value="dateType" is-link center @click="showActions = true" />
    </van-sticky>
    <div class="app-main-container">
      <div class="commission">
        <van-cell value-class="value-class" title="希田提成" :value="'￥'+xitianAmount" />
        <van-cell value-class="value-class" title="联盟提成" :value="'￥'+businessAmount" />
        <van-cell value-class="value-class" title="直播提成" :value="'￥'+liveAmount" />
      </div>
    </div>
    <van-popup v-model="showPopup" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @cancel="showPopup = false"
        @confirm="handleDatePickerConfirm"
      />
    </van-popup>
    <van-action-sheet v-model="showActions" :actions="actions" @select="handleSelectAction" />
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { toDateString, currentDate } from '@/utils'
import { getCommission } from '@/api/statistics'
export default {
  components: { myNavBar },
  data() {
    return {
      currentDate: new Date(),
      dateString: currentDate(),
      dateType: '日',
      xitianAmount: 0,
      businessAmount: 0,
      liveAmount: 0,
      showPopup: false,
      actions: [
        { name: '年' },
        { name: '月' },
        { name: '日' }
      ],
      showActions: false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const params = {
        date: this.dateString,
        type: this.dateType
      }
      this.beginLoad()
      getCommission(params).then(res => {
        this.endLoad()
        this.xitianAmount = res.data.xitian_commission_amount
        this.businessAmount = res.data.business_commission_amount
        this.liveAmount = res.data.live_commission_amount
      })
    },
    handleDatePickerConfirm(date) {
      this.dateString = toDateString(date)
      this.showPopup = false
      this.getData()
    },
    handleSelectAction(action) {
      this.dateType = action.name
      this.showActions = false
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/color";
.commission {
  padding-top: 10px;
}
.value-class {
  color: $amountRedColor;
}
</style>
